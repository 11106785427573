import {
  Box,
  Tooltip,
  Typography,
  TypographyProps,
  useTheme
} from '@mui/material';
import React from 'react';
import HelpIcon from '@mui/icons-material/Help';

export interface CutProps extends Omit<TypographyProps, 'color'> {
  CreditGain: number;
}

export const Credit = ({ CreditGain, ...typographyProps }: CutProps) => {
  return (
    <React.Fragment>
      <Box display={'inline-flex'}>
        <Typography variant="body2" {...typographyProps} color={'info'}>
          {CreditGain.toFixed(2)}{' '}
          {CreditGain > 0
            ? 'Credit Gain'
            : CreditGain == 0
            ? 'Credit'
            : 'Credit Loss'}
        </Typography>
      </Box>
      <Tooltip
        title={
          <Box>
            <Typography variant="body2">
              You can use credits to have your offers and signups to be shown at
              the top of the list (priority). Best part is you only spend your
              credits when you successfully complete the offer.
            </Typography>
          </Box>
        }
        disableInteractive
        placement="top-start"
      >
        <HelpIcon color="primary" sx={{ ml: 0.25, width: 15, height: 15 }} />
      </Tooltip>
    </React.Fragment>
  );
};

export default Credit;
