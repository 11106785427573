import { useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';

import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import HelpIcon from '@mui/icons-material/Help';
import { getBadgeColor, getUser } from 'src/models/user';
import { useDispatch } from 'react-redux';
import { useUserActivityStatus } from 'src/contexts/UserActivityContext';
import CooldownButton from 'src/components/Utility/CooldownButton';
import Text from 'src/components/Text';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const user = getUser();
  const limitedAccess =
    !(user?.Characters?.length > 0) &&
    (Object.values(user?.Score)?.every((value) => value < 100) ?? true);
  const dispatch = useDispatch();
  const { userStatus } = useUserActivityStatus();
  const badgeColor = getBadgeColor(
    userStatus === 'active' ? true : userStatus === 'idle' ? false : undefined
  );

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          variant="dot"
          color={badgeColor}
        >
          <Avatar
            variant="rounded"
            alt={user?.DiscordDetails?.Username}
            src={user?.DiscordDetails?.AvatarURL}
          />
        </Badge>
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user?.DiscordDetails?.Username}
            </UserBoxLabel>
            {user?.Karma !== undefined && (
              <UserBoxDescription variant="body2">
                <Text
                  color={
                    user?.Karma < 0
                      ? 'error'
                      : user?.Karma > 100
                      ? 'success'
                      : 'warning'
                  }
                >
                  {user?.Karma} Karma
                </Text>
                <Tooltip
                  title={
                    <Box>
                      <Typography variant="body2">
                        The intent of the karma system is to encourage users to
                        post/signup runs with higher cuts and discourage them
                        from posting/signing up runs with lower cuts. Check
                        under the "Karma" tab under "Statistics" for more
                        information.
                      </Typography>
                    </Box>
                  }
                  disableInteractive
                  placement="top-start"
                >
                  <HelpIcon
                    color="primary"
                    sx={{ ml: 0.1, width: 15, height: 15 }}
                  />
                </Tooltip>
              </UserBoxDescription>
            )}
            {user?.Credit !== undefined && (
              <UserBoxDescription variant="body2">
                <Text color="info">{user?.Credit.toFixed(2)} Credit</Text>{' '}
                <Tooltip
                  title={
                    <Box>
                      <Typography variant="body2">
                        You can use credits to have your offers and signups to
                        be shown at the top of the list (priority). Best part is
                        you only spend your credits when you successfully
                        complete the offer.
                      </Typography>
                    </Box>
                  }
                  disableInteractive
                  placement="top-start"
                >
                  <HelpIcon
                    color="primary"
                    sx={{ ml: 0.1, width: 15, height: 15 }}
                  />
                </Tooltip>
              </UserBoxDescription>
            )}
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            variant="dot"
            color={badgeColor}
          >
            <Avatar
              variant="rounded"
              alt={user?.DiscordDetails?.Username}
              src={user?.DiscordDetails?.AvatarURL}
            />
          </Badge>
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {user?.DiscordDetails?.Username}
            </UserBoxLabel>
            {user?.Karma !== undefined && (
              <UserBoxDescription variant="body2">
                <Text
                  color={
                    user?.Karma < 0
                      ? 'error'
                      : user?.Karma > 100
                      ? 'success'
                      : 'warning'
                  }
                >
                  {user?.Karma} Karma
                </Text>
                <Tooltip
                  title={
                    <Box>
                      <Typography variant="body2">
                        The intent of the karma system is to encourage users to
                        post/signup runs with higher cuts and discourage them
                        from posting/signing up runs with lower cuts. Check
                        under the "Karma" tab under "Statistics" for more
                        information.
                      </Typography>
                    </Box>
                  }
                  disableInteractive
                  placement="top-start"
                >
                  <HelpIcon
                    color="primary"
                    sx={{ ml: 0.1, width: 15, height: 15 }}
                  />
                </Tooltip>
              </UserBoxDescription>
            )}
            {user?.Credit !== undefined && (
              <UserBoxDescription variant="body2">
                <Text color="info">{user?.Credit.toFixed(2)} Credit</Text>{' '}
                <Tooltip
                  title={
                    <Box>
                      <Typography variant="body2">
                        You can use credits to have your offers and signups to
                        be shown at the top of the list (priority). Best part is
                        you only spend your credits when you successfully
                        complete the offer.
                      </Typography>
                    </Box>
                  }
                  disableInteractive
                  placement="top-start"
                >
                  <HelpIcon
                    color="primary"
                    sx={{ ml: 0.1, width: 15, height: 15 }}
                  />
                </Tooltip>
              </UserBoxDescription>
            )}
            <UserBoxDescription variant="body2">
              {user?.Premium?.Tier === 0 ? (
                <CooldownButton
                  cooldownSeconds={60}
                  color="secondary"
                  variant="text"
                  sx={{ p: 0 }}
                  onClick={() => {
                    dispatch({
                      type: 'socket/Message/send',
                      payload: {
                        type: 'force_premium_update'
                      }
                    });
                  }}
                >
                  Free User (Wrong? Click)
                </CooldownButton>
              ) : (
                'Premium'
              )}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItemButton to="/dashboards/profile" component={NavLink}>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <AccountBoxTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </ListItemButton>
          {!limitedAccess && (
            <ListItemButton to="/settings" component={NavLink}>
              <ListItemIcon sx={{ minWidth: 30 }}>
                <AccountTreeTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="Account Settings" />
            </ListItemButton>
          )}
          {!limitedAccess && (
            <ListItemButton component={NavLink} to="/settings/notifications">
              <ListItemIcon sx={{ minWidth: 30 }}>
                <AnnouncementIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Notification Settings" />
            </ListItemButton>
          )}
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            onClick={() => dispatch({ type: 'login/disconnect' })}
            fullWidth
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
