export interface RaidBoss {
  Name: string;
  Emoji: string;
  Image: string;
  Short: string;
}

export const RaidBosses: RaidBoss[] = [
  {
    Name: 'Vexie and the Geargrinders',
    Emoji: '/static/images/WoW/Raids/TWW2/Vexie and the Geargrinders.png',
    Image: '/static/images/WoW/Raids/TWW2/Vexie and the Geargrinders.png',
    Short: 'Vexie'
  },
  {
    Name: 'Cauldron of Carnage',
    Emoji: '/static/images/WoW/Raids/TWW2/Cauldron of Carnage.png',
    Image: '/static/images/WoW/Raids/TWW2/Cauldron of Carnage.png',
    Short: 'Cauldron'
  },
  {
    Name: 'Stix Bunkjunker',
    Emoji: '/static/images/WoW/Raids/TWW2/Stix Bunkjunker.png',
    Image: '/static/images/WoW/Raids/TWW2/Stix Bunkjunker.png',
    Short: 'Stix'
  },
  {
    Name: 'Rik Reverb',
    Emoji: '/static/images/WoW/Raids/TWW2/Rik Reverb.png',
    Image: '/static/images/WoW/Raids/TWW2/Rik Reverb.png',
    Short: 'Rik'
  },
  {
    Name: 'Sprocketmonger Lockenstock',
    Emoji: '/static/images/WoW/Raids/TWW2/Sprocketmonger Lockenstock.png',
    Image: '/static/images/WoW/Raids/TWW2/Sprocketmonger Lockenstock.png',
    Short: 'Sprock'
  },
  {
    Name: 'The One-Armed Bandit',
    Emoji: '/static/images/WoW/Raids/TWW2/The One-Armed Bandit.png',
    Image: '/static/images/WoW/Raids/TWW2/The One-Armed Bandit.png',
    Short: 'Bandit'
  },
  {
    Name: "Mug'Zee, Heads of Security",
    Emoji: "/static/images/WoW/Raids/TWW2/Mug'Zee, Heads of Security.png",
    Image: "/static/images/WoW/Raids/TWW2/Mug'Zee, Heads of Security.png",
    Short: "Mug'Zee"
  },
  {
    Name: 'Chrome King Gallywix',
    Emoji: '/static/images/WoW/Raids/TWW2/Chrome King Gallywix.png',
    Image: '/static/images/WoW/Raids/TWW2/Chrome King Gallywix.png',
    Short: 'Gallywix'
  },
  {
    Name: 'Full Clear',
    Emoji: '/static/images/WoW/Raids/TWW2/Chrome King Gallywix.png',
    Image: '/static/images/WoW/Raids/TWW2/Chrome King Gallywix.png',
    Short: 'Full Clear'
  }
];
