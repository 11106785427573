export interface Medal {
  Name: string;
  Description: string;
  Image: string;
}

const Medals = [
  {
    Name: 'Renowned Tank',
    Description: 'Top 10 in Monthly Tank Leaderboard',
    Image: '/static/images/medals/Tank.png',
    Short: 'Tank',
    Color: '#4B5320' // Army green to represent strength and resilience
  },
  {
    Name: 'Renowned Healer',
    Description: 'Top 10 in Monthly Healer Leaderboard',
    Image: '/static/images/medals/Healer.png',
    Short: 'Healer',
    Color: '#00FF7F' // Medium spring green to represent healing and nature
  },
  {
    Name: 'Renowned DPS',
    Description: 'Top 10 in Monthly DPS Leaderboard',
    Image: '/static/images/medals/DPS.png',
    Short: 'DPS',
    Color: '#FF4500' // Orange red to represent energy and aggression
  },
  {
    Name: 'Protector of the Market',
    Description: 'Top 10 in Monthly Karma Leaderboard',
    Image: '/static/images/medals/Karma.png',
    Short: 'Karma',
    Color: '#FFD700' // Gold to represent value and wealth
  },
  {
    Name: 'Famed Slayer of Fyrakk',
    Description: 'Hall of Fame in Dragonflight Season 3',
    Image: '/static/images/medals/hof-season-df-3.png',
    Short: 'hof-season-df-3',
    Color: '#FF6347' // Tomato red to represent battle and heroism
  },
  {
    Name: 'Famed Slayer of Ansurak',
    Description: 'Hall of Fame in The War Within Season 1',
    Image: '/static/images/medals/hof-season-tww-1.png',
    Short: 'hof-season-tww-1',
    Color: '#9370DB' // Purple to represent mystery and magic
  },
  {
    Name: 'Dreaming Hero',
    Description: 'Top 0.1% in Raider.IO Score in Dragonflight Season 3',
    Image: '/static/images/medals/hero-season-df-3.png',
    Short: 'hero-season-df-3',
    Color: '#1E90FF' // Dodger blue to represent dreams and aspirations
  },
  {
    Name: 'Draconic Hero',
    Description: 'Top 0.1% in Raider.IO Score in Dragonflight Season 4',
    Image: '/static/images/medals/hero-season-df-4.png',
    Short: 'hero-season-df-4',
    Color: '#8A2BE2' // Blue violet to represent magic and heroism
  },
  {
    Name: 'Tempered Hero',
    Description: 'Top 0.1% in Raider.IO Score in The War Within Season 1',
    Image: '/static/images/medals/hero-season-tww-1.png',
    Short: 'hero-season-tww-1',
    Color: '#87CEFA'
  },
  {
    Name: 'WoWLFG Top Supporter',
    Description: 'Premium 3 Subscriber of WoWLFG',
    Image: '/static/images/medals/Premium.png',
    Short: 'Premium',
    Color: '#DAA520' // Golden rod to represent premium support and distinction
  },
  {
    Name: 'WoWLFG Support',
    Description: 'Support Member of WoWLFG',
    Image: '/static/images/medals/Support.png',
    Short: 'Support',
    Color: '#13ddff' // Gold to represent support and value
  }
];

export const getMedalFromShort = (medal: string) => {
  return Medals.find((m) => m.Short === medal);
};

export const getUserMedals = (medals: string[]) => {
  return medals?.map((medal) => getMedalFromShort(medal));
};

export const getUserMainMedal = (medals: string[], mainMedal: string) => {
  if (medals && medals.length > 0) {
    return getMedalFromShort(
      mainMedal && medals.includes(mainMedal) ? mainMedal : medals[0]
    );
  } else return undefined;
};
