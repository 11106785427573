export interface Dungeon {
  Name: string;
  Emoji: string;
  Image: string;
  Short: string;
}

// export const Dungeons: Dungeon[] = [
//   {
//     Name: 'Halls of Infusion',
//     Emoji: '/static/images/WoW/Dungeons/DF2/HoI.png',
//     Image: '/static/images/WoW/Dungeons/DF2/HoI.png',
//     Short: 'HoI'
//   },
//   {
//     Name: 'Brackenhide Hollow',
//     Emoji: '/static/images/WoW/Dungeons/DF2/BH.png',
//     Image: '/static/images/WoW/Dungeons/DF2/BH.png',
//     Short: 'BH'
//   },
//   {
//     Name: 'Uldaman: Legacy of Tyr',
//     Emoji: '/static/images/WoW/Dungeons/DF2/Uld.png',
//     Image: '/static/images/WoW/Dungeons/DF2/Uld.png',
//     Short: 'Uldaman'
//   },
//   {
//     Name: 'Neltharus',
//     Emoji: '/static/images/WoW/Dungeons/DF2/NT.png',
//     Image: '/static/images/WoW/Dungeons/DF2/NT.png',
//     Short: 'NT'
//   },
//   {
//     Name: "Neltharion's Lair",
//     Emoji: '/static/images/WoW/Dungeons/DF2/NL.png',
//     Image: '/static/images/WoW/Dungeons/DF2/NL.png',
//     Short: 'NL'
//   },
//   {
//     Name: 'Freehold',
//     Emoji: '/static/images/WoW/Dungeons/DF2/FH.png',
//     Image: '/static/images/WoW/Dungeons/DF2/FH.png',
//     Short: 'FH'
//   },
//   {
//     Name: 'The Underrot',
//     Emoji: '/static/images/WoW/Dungeons/DF2/UR.png',
//     Image: '/static/images/WoW/Dungeons/DF2/UR.png',
//     Short: 'UR'
//   },
//   {
//     Name: 'The Vortex Pinnacle',
//     Emoji: '/static/images/WoW/Dungeons/DF2/VP.png',
//     Image: '/static/images/WoW/Dungeons/DF2/VP.png',
//     Short: 'VP'
//   },
//   {
//     Name: 'Dawn of the Infinite',
//     Emoji: '/static/images/WoW/Dungeons/DF2/DI.jpg',
//     Image: '/static/images/WoW/Dungeons/DF2/DI.jpg',
//     Short: 'DoI'
//   }
// ];

// export const Dungeons: Dungeon[] = [
//   {
//     Name: 'Waycrest Manor',
//     Emoji: '/static/images/WoW/Dungeons/DF3/WM.png',
//     Image: '/static/images/WoW/Dungeons/DF3/WM.png',
//     Short: 'WM'
//   },
//   {
//     Name: 'Black Rook Hold',
//     Emoji: '/static/images/WoW/Dungeons/DF3/BRH.png',
//     Image: '/static/images/WoW/Dungeons/DF3/BRH.png',
//     Short: 'BRH'
//   },
//   {
//     Name: 'Throne of the Tides',
//     Emoji: '/static/images/WoW/Dungeons/DF3/ToT.png',
//     Image: '/static/images/WoW/Dungeons/DF3/ToT.png',
//     Short: 'ToT'
//   },
//   {
//     Name: 'The Everbloom',
//     Emoji: '/static/images/WoW/Dungeons/DF3/EB.png',
//     Image: '/static/images/WoW/Dungeons/DF3/EB.png',
//     Short: 'EB'
//   },
//   {
//     Name: 'Darkheart Thicket',
//     Emoji: '/static/images/WoW/Dungeons/DF3/DT.png',
//     Image: '/static/images/WoW/Dungeons/DF3/DT.png',
//     Short: 'DT'
//   },
//   {
//     Name: "Atal'Dazar",
//     Emoji: '/static/images/WoW/Dungeons/DF3/AD.png',
//     Image: '/static/images/WoW/Dungeons/DF3/AD.png',
//     Short: 'AD'
//   },
//   {
//     Name: "Dawn of the Infinite: Galakrond's Fall",
//     Emoji: '/static/images/WoW/Dungeons/DF3/DI.jpg',
//     Image: '/static/images/WoW/Dungeons/DF3/DI.jpg',
//     Short: 'DoI-GF'
//   },
//   {
//     Name: "Dawn of the Infinite: Murozond's Rise",
//     Emoji: '/static/images/WoW/Dungeons/DF3/DI.jpg',
//     Image: '/static/images/WoW/Dungeons/DF3/DI.jpg',
//     Short: 'DoI-MR'
//   }
// ];

// export const Dungeons: Dungeon[] = [
//   {
//     Name: 'Halls of Infusion',
//     Emoji: '/static/images/WoW/Dungeons/DF4/HoI.png',
//     Image: '/static/images/WoW/Dungeons/DF4/HoI.png',
//     Short: 'HoI'
//   },
//   {
//     Name: 'Brackenhide Hollow',
//     Emoji: '/static/images/WoW/Dungeons/DF4/BH.png',
//     Image: '/static/images/WoW/Dungeons/DF4/BH.png',
//     Short: 'BH'
//   },
//   {
//     Name: 'Uldaman: Legacy of Tyr',
//     Emoji: '/static/images/WoW/Dungeons/DF4/Uld.png',
//     Image: '/static/images/WoW/Dungeons/DF4/Uld.png',
//     Short: 'Uldaman'
//   },
//   {
//     Name: 'Neltharus',
//     Emoji: '/static/images/WoW/Dungeons/DF2/NT.png',
//     Image: '/static/images/WoW/Dungeons/DF2/NT.png',
//     Short: 'NT'
//   },
//   {
//     Name: 'The Azure Vault',
//     Emoji: '/static/images/WoW/Dungeons/DF4/AV.png',
//     Image: '/static/images/WoW/Dungeons/DF4/AV.png',
//     Short: 'AV'
//   },
//   {
//     Name: 'The Nokhud Offensive',
//     Emoji: '/static/images/WoW/Dungeons/DF4/NO.png',
//     Image: '/static/images/WoW/Dungeons/DF4/NO.png',
//     Short: 'NO'
//   },
//   {
//     Name: 'Ruby Life Pools',
//     Emoji: '/static/images/WoW/Dungeons/DF4/RLP.png',
//     Image: '/static/images/WoW/Dungeons/DF4/RLP.png',
//     Short: 'RLP'
//   },
//   {
//     Name: "Algeth'ar Academy",
//     Emoji: '/static/images/WoW/Dungeons/DF4/AA.png',
//     Image: '/static/images/WoW/Dungeons/DF4/AA.png',
//     Short: 'AA'
//   }
// ];

export const Dungeons: Dungeon[] = [
  // {
  //   Name: 'Ara-Kara, City of Echoes',
  //   Emoji: '/static/images/WoW/Dungeons/TWW1/Ara-Kara, City of Echoes.png',
  //   Image: '/static/images/WoW/Dungeons/TWW1/Ara-Kara, City of Echoes.png',
  //   Short: 'AK'
  // },
  // {
  //   Name: 'City of Threads',
  //   Emoji: '/static/images/WoW/Dungeons/TWW1/City of Threads.png',
  //   Image: '/static/images/WoW/Dungeons/TWW1/City of Threads.png',
  //   Short: 'CoT'
  // },
  // {
  //   Name: 'The Stonevault',
  //   Emoji: '/static/images/WoW/Dungeons/TWW1/The Stonevault.png',
  //   Image: '/static/images/WoW/Dungeons/TWW1/The Stonevault.png',
  //   Short: 'SV'
  // },
  // {
  //   Name: 'The Dawnbreaker',
  //   Emoji: '/static/images/WoW/Dungeons/TWW1/The Dawnbreaker.png',
  //   Image: '/static/images/WoW/Dungeons/TWW1/The Dawnbreaker.png',
  //   Short: 'DB'
  // },
  {
    Name: 'Cinderbrew Meadery',
    Emoji: '/static/images/WoW/Dungeons/TWW2/Cinderbrew Meadery.png',
    Image: '/static/images/WoW/Dungeons/TWW2/Cinderbrew Meadery.png',
    Short: 'CM'
  },
  {
    Name: 'Darkflame Cleft',
    Emoji: '/static/images/WoW/Dungeons/TWW2/Darkflame Cleft.png',
    Image: '/static/images/WoW/Dungeons/TWW2/Darkflame Cleft.png',
    Short: 'DC'
  },
  {
    Name: 'Operation: Floodgate',
    Emoji: '/static/images/WoW/Dungeons/TWW2/Floodgate.png',
    Image: '/static/images/WoW/Dungeons/TWW2/Floodgate.png',
    Short: 'FG'
  },
  {
    Name: 'The MOTHERLODE!!',
    Emoji: '/static/images/WoW/Dungeons/TWW2/motherlode.png',
    Image: '/static/images/WoW/Dungeons/TWW2/motherlode.png',
    Short: 'ML'
  },
  {
    Name: 'Priory of the Sacred Flame',
    Emoji: '/static/images/WoW/Dungeons/TWW2/Priory of the Sacred Flame.png',
    Image: '/static/images/WoW/Dungeons/TWW2/Priory of the Sacred Flame.png',
    Short: 'PSF'
  },
  {
    Name: 'The Rookery',
    Emoji: '/static/images/WoW/Dungeons/TWW2/The Rookery.png',
    Image: '/static/images/WoW/Dungeons/TWW2/The Rookery.png',
    Short: 'RK'
  },
  {
    Name: 'Theater of Pain',
    Emoji: '/static/images/WoW/Dungeons/TWW2/top.png',
    Image: '/static/images/WoW/Dungeons/TWW2/top.png',
    Short: 'ToP'
  },
  {
    Name: 'Operation: Mechagon - Workshop',
    Emoji: '/static/images/WoW/Dungeons/TWW2/workshop.png',
    Image: '/static/images/WoW/Dungeons/TWW2/workshop.png',
    Short: 'Workshop'
  }
  // {
  //   Name: 'Mists of Tirna Scithe',
  //   Emoji: '/static/images/WoW/Dungeons/TWW1/Mists of Tirna Scithe.png',
  //   Image: '/static/images/WoW/Dungeons/TWW1/Mists of Tirna Scithe.png',
  //   Short: 'Mist'
  // },
  // {
  //   Name: 'The Necrotic Wake',
  //   Emoji: '/static/images/WoW/Dungeons/TWW1/The Necrotic Wake.png',
  //   Image: '/static/images/WoW/Dungeons/TWW1/The Necrotic Wake.png',
  //   Short: 'NW'
  // },
  // {
  //   Name: 'Siege of Boralus',
  //   Emoji: '/static/images/WoW/Dungeons/TWW1/Siege of Boralus.png',
  //   Image: '/static/images/WoW/Dungeons/TWW1/Siege of Boralus.png',
  //   Short: 'SoB'
  // },
  // {
  //   Name: 'Grim Batol',
  //   Emoji: '/static/images/WoW/Dungeons/TWW1/Grim Batol.png',
  //   Image: '/static/images/WoW/Dungeons/TWW1/Grim Batol.png',
  //   Short: 'GB'
  // }
];

export const DungeonList: string[] = Dungeons.map((dungeon) => dungeon.Name);
